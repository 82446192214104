@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Fonts */
.instrument-serif-regular {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.instrument-serif-regular-italic {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: italic;
}

.instrument-sans {
  font-family: "Instrument Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

body {
  background-color: #151514;
}

/* Custom Range Input Styling */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: rgba(181, 235, 227, 0.2);
  border-radius: 9999px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #facc15;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #facc15;
  cursor: pointer;
}

/* Fixed Bottom Element */
.fixed-bottom {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}